import { Divider } from '@mui/material';
import { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import DialogSubtitle from 'src/components/DialogTitle/DialogSubtitle';

export type DialogTitleGroupProps<
  TSubtitleElementType extends React.ElementType = 'p'
> = {
  showDivider?: boolean;
  title: React.ReactElement;
  subtitle?: React.ReactNode;
  subtitleComponent?: React.ElementType;
  subtitleProps?: Partial<MuiDialogTitleProps<TSubtitleElementType>>;
};

/**
 * @package
 */
const DialogTitleGroup: React.FC<DialogTitleGroupProps> =
  function DialogTitleGroup(props) {
    const {
      showDivider,
      title,
      subtitle,
      subtitleComponent = 'p',
      subtitleProps,
      ...rest
    } = props;

    // For now, we're always rendering a visually hidden `<hr>` element below the
    // dialog heading element to prevent the top padding on the adjacent DialogContent
    // from collapsing due to CSS padding collapse behavior that the UI team does not want.
    //
    // In a follow-up effort, this same Divider will conditionally be visible to the
    // user when a new `showDivider` prop is made available.
    const renderedDivider = (
      <Divider
        sx={{ ...(!showDivider && visuallyHidden) }}
        aria-hidden={!showDivider}
      />
    );

    return (
      <hgroup role="group" aria-roledescription="Heading group" {...rest}>
        {title}
        <DialogSubtitle component={subtitleComponent} {...subtitleProps}>
          {subtitle}
        </DialogSubtitle>
        {renderedDivider}
      </hgroup>
    );
  };

export default DialogTitleGroup;
