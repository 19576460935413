import {
  fabClasses as muiFabClasses,
  styled,
  useThemeProps,
} from '@mui/material';
import {
  default as MuiFab,
  FabOwnProps as MuiFabOwnProps,
  FabTypeMap as MuiFabTypeMap,
} from '@mui/material/Fab';
import { OverrideProps } from '@mui/material/OverridableComponent';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { rootShouldForwardProp } from '@mui/material/styles/styled';
import clsx from 'clsx';
import * as React from 'react';
import assistantChatFabClasses from 'src/eap/components/WorkivaAssistant/AssistantChatFab/assistantChatFabClasses';
import assistantFabClasses from 'src/eap/components/WorkivaAssistant/AssistantFab/assistantFabClasses';
import AssistantIcon, {
  assistantIconSizeNameByFabSize,
} from 'src/eap/components/WorkivaAssistant/AssistantIcon/AssistantIcon';
import { getThemePalette } from 'src/styles/utils';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

// NOTE: You must update the `minWidth` of `AssistantFabRoot` if this changes.
export const defaultAssistantFabText = 'Get started with Generative AI';
export const defaultAssistantFabSize: NonNullable<MuiFabOwnProps['size']> =
  'small';

export interface AssistantFabOwnProps {
  /**
   * If `true`, the component is disabled.
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Set to true when the fab should appear "active" / "selected".
   *
   * @default false
   */
  active?: boolean;
  /**
   * The text to display in the button.
   *
   * @default 'Get started with Workiva Assistant'
   */
  text?: string;
}

export type AssistantFabTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiFabTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Pick<MuiFabTypeMap['props'], 'size'>;
    defaultComponent: RootComponent;
  },
  AdditionalProps & AssistantFabOwnProps,
  RootComponent
>;

export type AssistantFabProps<
  RootComponent extends React.ElementType = MuiFabTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  AssistantFabTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

const AssistantFabRoot = styled(MuiFab, {
  name: 'AssistantFab',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;

    return [styles.root, ownerState.active && styles.active];
  },
  shouldForwardProp: (propKey: string) =>
    rootShouldForwardProp(propKey) && !['active', 'text'].includes(propKey),
})(function AssistantFabRoot({ theme }) {
  const palette = getThemePalette(theme);
  const inactiveBg = palette.background.paper;
  const boxShadow = (theme.vars || theme).shadows[12];

  // When displayed in an AssistantChatFab component,
  // the mouse events are on the parent element which
  // includes 3 units of theme spacing to the right of
  // the fab button.
  //
  // That "gutter" remains hoverable / clickable though -
  // to alleviate user frustration when the button slides
  // out often past where their cursor position was when the
  // expansion was initiated. So we need the hover style to
  // remain even when its the parent element being hovered.
  const hoverSelectors = `&:hover, .${assistantChatFabClasses.root}:hover > &`;

  return {
    // NOTE: This value needs to change if `defaultAssistantFabText` changes.
    minWidth: 175,
    // So that two rows of text fit within the button height
    // when the button width is shrunk by layout constraints.
    lineHeight: 1,
    color: palette.Link?.color,
    backgroundColor: inactiveBg,
    boxShadow,

    [`${hoverSelectors}`]: {
      color: palette.Link?.colorHover,
      backgroundColor: inactiveBg,
    },
    '&:active': {
      boxShadow,
    },
    [`&.${muiFabClasses.focusVisible}`]: {
      color: palette.Link?.colorHover,
      backgroundColor: inactiveBg,
    },

    [`&.${muiFabClasses.disabled}`]: {
      color: palette.action.disabled,
      boxShadow: (theme.vars || theme).shadows[2],
      backgroundColor: inactiveBg,
    },
  };
});

/**
 * A floating action button to be used **for the Workiva Assistant EAP experience only.**
 *
 * ------------------------- WARNING ---------------------------
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 *   DO NOT USE THIS COMPONENT UNLESS YOU HAVE
 *   EXPLICIT PERMISSION TO DO SO FROM THE DESIGN SYSTEM TEAM.
 *
 *   SERIOUSLY.
 *
 *   DO NOT USE IT.
 *
 *   DO NOT COPY IT.
 *
 *   DO NOT DERIVE INSPIRATION FROM IT.
 *
 *   DO NOT IMITATE IT.
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * ------------------------- /WARNING --------------------------
 */
export const AssistantFab = forwardRefToOverridableComponent<
  AssistantFabTypeMap,
  AssistantFabProps
>(function AssistantFab(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiFab' });
  const {
    className,
    active = false,
    size = defaultAssistantFabSize,
    text = defaultAssistantFabText,
    ...rest
  } = props;

  return (
    <AssistantFabRoot
      {...rest}
      aria-expanded={active}
      size={size}
      color="inherit"
      className={clsx(assistantFabClasses.root, className)}
      variant="extended"
      ref={forwardedRef}
    >
      <AssistantIcon
        color="inherit"
        fontSize={assistantIconSizeNameByFabSize[size]}
        sx={{
          marginRight: (theme) => theme.spacing(1),
        }}
      />
      {text}
    </AssistantFabRoot>
  );
});

export default AssistantFab;
