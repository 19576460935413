import { default as dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * START Locales supported by WDesk
 *
 * The latest list can be obtained via code from  `package:w_intl/languages.dart` and using the `allDateLocales` var.
 * See the DatePicker localization example (example/date_picker/localization_example/w_intl_locales.dart) for a code snippet previously used.
 * OR
 * The it can be obtained from https://wiki.atl.workiva.net/display/FEF/Internationalization, but be aware that this
 * link does not include English or any of the "classic" language/date formatting options that are still available.
 *
 * Also when updating this list do not forget to format the locale strings using dayjs locale format
 * `{languageCode}[-{regionCode}]`, and if dayjs doesn't have the exact region code the language code alone is the right choice.
 *
 * TO UPDATE THIS LIST:
 * switch to the `generate-dayjs-locale-import-list` branch
 * UPDATE W_INTL to the latest version and `dart pub get`
 * run `dart pub run build_runner serve example`
 * Navigate to http://localhost:8080/date_picker/localization_example/
 * Open dev tools console to copy/paste list of locales in the console.
 **/
import 'dayjs/locale/de';
import 'dayjs/locale/de-ch';
import 'dayjs/locale/en';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/nb';
import 'dayjs/locale/nl';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/x-pseudo';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
// END Locales supported by WDesk

/**
 * Below are all the dayjs locales that are also in dart `intl` (https://github.com/dart-lang/i18n/blob/main/pkgs/intl/lib/date_time_patterns.dart).
 */
// import 'dayjs/locale/am';
// import 'dayjs/locale/ar';
// import 'dayjs/locale/ar-dz';
// import 'dayjs/locale/ar-iq';
// import 'dayjs/locale/ar-kw';
// import 'dayjs/locale/ar-ly';
// import 'dayjs/locale/ar-ma';
// import 'dayjs/locale/ar-sa';
// import 'dayjs/locale/ar-tn';
// import 'dayjs/locale/az';
// import 'dayjs/locale/be';
// import 'dayjs/locale/bg';
// import 'dayjs/locale/bm';
// import 'dayjs/locale/bn';
// import 'dayjs/locale/bn-bd';
// import 'dayjs/locale/br';
// import 'dayjs/locale/bs';
// import 'dayjs/locale/ca';
// import 'dayjs/locale/cs';
// import 'dayjs/locale/cy';
// import 'dayjs/locale/da';
// import 'dayjs/locale/de-at';
// import 'dayjs/locale/el';
// import 'dayjs/locale/en-au';
// import 'dayjs/locale/en-ie';
// import 'dayjs/locale/en-il';
// import 'dayjs/locale/en-in';
// import 'dayjs/locale/en-nz';
// import 'dayjs/locale/en-sg';
// import 'dayjs/locale/en-tt';
// import 'dayjs/locale/es-do';
// import 'dayjs/locale/es-pr';
// import 'dayjs/locale/es-us';
// import 'dayjs/locale/et';
// import 'dayjs/locale/eu';
// import 'dayjs/locale/fa';
// import 'dayjs/locale/fi';
// import 'dayjs/locale/fr-ch';
// import 'dayjs/locale/ga';
// import 'dayjs/locale/gl';
// import 'dayjs/locale/gu';
// import 'dayjs/locale/he';
// import 'dayjs/locale/hi';
// import 'dayjs/locale/hr';
// import 'dayjs/locale/hu';
// import 'dayjs/locale/hy-am';
// import 'dayjs/locale/id';
// import 'dayjs/locale/is';
// import 'dayjs/locale/it-ch';
// import 'dayjs/locale/ka';
// import 'dayjs/locale/kk';
// import 'dayjs/locale/km';
// import 'dayjs/locale/kn';
// import 'dayjs/locale/ky';
// import 'dayjs/locale/lo';
// import 'dayjs/locale/lt';
// import 'dayjs/locale/lv';
// import 'dayjs/locale/mk';
// import 'dayjs/locale/ml';
// import 'dayjs/locale/mn';
// import 'dayjs/locale/mr';
// import 'dayjs/locale/ms';
// import 'dayjs/locale/ms-my';
// import 'dayjs/locale/mt';
// import 'dayjs/locale/my';
// import 'dayjs/locale/ne';
// import 'dayjs/locale/nl-be';
// import 'dayjs/locale/pa-in';
// import 'dayjs/locale/ro';
// import 'dayjs/locale/ru';
// import 'dayjs/locale/si';
// import 'dayjs/locale/sk';
// import 'dayjs/locale/sl';
// import 'dayjs/locale/sq';
// import 'dayjs/locale/sr';
// import 'dayjs/locale/sr-cyrl';
// import 'dayjs/locale/sv';
// import 'dayjs/locale/sv-fi';
// import 'dayjs/locale/sw';
// import 'dayjs/locale/ta';
// import 'dayjs/locale/te';
// import 'dayjs/locale/th';
// import 'dayjs/locale/tl-ph';
// import 'dayjs/locale/tr';
// import 'dayjs/locale/uk';
// import 'dayjs/locale/ur';
// import 'dayjs/locale/uz';
// import 'dayjs/locale/uz-latn';
// import 'dayjs/locale/vi';
// import 'dayjs/locale/zh';
// import 'dayjs/locale/zh-hk';

export { dayjs };
