import {
  styled,
  Typography,
  TypographyProps,
  useThemeProps,
} from '@mui/material';
import { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import { ComponentsProps, ComponentsVariants } from '@mui/material/styles';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import { muiDialogContentHorizontalSpacing } from 'src/components/DialogContent/dialogContentStyleOverrides';
import dialogSubtitleClasses, {
  DialogSubtitleClassKey,
} from 'src/components/DialogTitle/dialogSubtitleClasses';

declare module '@mui/material/styles' {
  interface ComponentNameToClassKey {
    MuiDialogSubtitle: DialogSubtitleClassKey;
  }

  interface ComponentsPropsList {
    MuiDialogSubtitle: DialogSubtitleProps;
  }

  interface Components {
    MuiDialogSubtitle?: {
      defaultProps?: ComponentsProps['MuiDialogSubtitle'];
      variants?: ComponentsVariants['MuiDialogSubtitle'];
    };
  }
}

const DialogSubtitleRoot = styled(Typography, {
  name: 'MuiDialogSubtitle',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})<TypographyProps & Pick<DialogSubtitleProps, 'component'>>(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: `0 ${spacing(muiDialogContentHorizontalSpacing)} ${spacing(1)}`,
  };
});

export type DialogSubtitleProps<D extends React.ElementType = 'p'> = Omit<
  MuiDialogTitleProps<D>,
  'component'
> &
  Pick<TypographyProps<D>, 'component'>;

/**
 * @package
 */
const DialogSubtitle: React.FC<DialogSubtitleProps> = function DialogSubtitle(
  inProps
) {
  const themeProps = useThemeProps({
    props: inProps,
    name: 'MuiDialogSubtitle',
  });
  const props = extendSxProp(themeProps);
  const {
    className,
    component = 'p',
    variant = 'subtitle2',
    children,
    ...rest
  } = props;
  const rootClasses = clsx([dialogSubtitleClasses.root, className]);

  return (
    <DialogSubtitleRoot
      className={rootClasses}
      component={component}
      {...rest}
      variant={variant}
      aria-roledescription="subtitle"
    >
      {children}
    </DialogSubtitleRoot>
  );
};

export default DialogSubtitle;
