import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  AppBar as MuiAppBar,
  AppBarTypeMap as MuiAppBarTypeMap,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import { useUnifyElevationProp } from 'src/styles/elevationUtils';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface AppBarOwnProps {
  /**
   * Unify system elevation level that determines shadow depth when `variant` is not set to `outlined`.
   * Accepts values between 0 and 6 inclusive.
   * @default 2
   */
  elevation?: number;
}

export type AppBarTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiAppBarTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Omit<MuiAppBarTypeMap['props'], 'elevation'>;
    defaultComponent: MuiAppBarTypeMap['defaultComponent'];
  },
  AdditionalProps & AppBarOwnProps,
  RootComponent
>;

export type AppBarProps<
  RootComponent extends React.ElementType = MuiAppBarTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<AppBarTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const AppBar = forwardRefToOverridableComponent<
  AppBarTypeMap,
  AppBarProps
>(function AppBar(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiAppBar' });
  const {
    children,
    elevation: unifyElevation = props.variant === 'outlined' ? 0 : 2,
    ...rest
  } = props;

  const muiElevation = useUnifyElevationProp(unifyElevation);

  return (
    <MuiAppBar {...rest} ref={forwardedRef} elevation={muiElevation}>
      {children}
    </MuiAppBar>
  );
});

export default AppBar;
